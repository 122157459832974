<template>
    <div>
        <!-- 图片详情 -->
        <base-dialog 
            width="1000px" 
            height="900px" 
            :label="article.title||'文章详情'"
            v-show="dialogFlag"
            :show-close="true"
            @closeDialog="closeDialog"
        >
            <div class="wrap">
                <el-scrollbar style="height:100%;">
                    <div class="top">
                        <label>时间：</label>
                        <span>{{article.createtime}}</span>
                    </div>
                    <div class="img_wrap" v-show="article.imgUrl">
                        <img :src="article.imgUrl" @error="e => { e.target.src = defaultImg }" alt="">
                        <!-- <p>白菜-454545</p> -->
                    </div>
                    <div class="content" v-html="article.content">
                        <!-- <p>文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容</p>
                        <p>文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容</p>
                        <p>文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容</p>
                        <p>文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容</p>
                        <p>文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容</p> -->
                    </div>
                </el-scrollbar>
            </div>
        </base-dialog>
    </div>
</template>
<script>
import BaseDialog from '@/components/dialog/baseDialog' //弹框
// import { imageUrl } from '@/utils/config'
export default {
    components:{BaseDialog},
    data(){
        return {
            dialogFlag:false,
            article:{},
            defaultImg:require('@/assets/images/default_img.png'),
            // imageUrl:sysUrl,
        }
    },
    mounted(){
       
    },
    methods:{
        closeDialog(){//关闭弹框
            this.dialogFlag=false
        },
        show(val){
            this.article=val
            this.dialogFlag=true
        }
    }
}
</script>

<style lang='scss' scoped>
    @import '~@/assets/css/editor.css';
    .wrap{
        // border: 3px solid #f00;
        height: 100%;
        padding: 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .top{
            // border: 3px solid #f00;
            font-size: 20px;
            text-align: center;
            label{
                color: #fff;
                display: inline-block;
                width: 100px;
                text-align: right;
            }
            span{
                color: #10C4FF;
            }
        }
        .img_wrap{
            flex:none;
            // margin-left: 65px;
            // border: 3px solid #0f0;
            margin: 20px auto;
            width: 425px;
            height: 320px;
            font-size: 0;
            background: url('~@/assets/images/dialog/qyxx_border.png') no-repeat center/100% 100%;
            padding: 15px 10px 10px 18px;
            box-sizing: border-box;
            position: relative;
            p{
                position: absolute;
                left: 18px;
                right: 10px;
                bottom: 10px;
                font-size: 14px;
                height: 35px;
                text-align: center;
                line-height: 35px;
                background-color: rgba(0,0,0,.57);
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
        .content{
            // border: 3px solid #0f0;
            margin-top: 20px;
            flex: 1;
            box-sizing: border-box;
            padding: 0 20px;
        }
    }
</style>