<template>
  <div>
    <!-- 品牌展示 -->
    <base-dialog
      width="1000px"
      height="500px"
      label="品牌展示"
      v-show="dialogFlag"
      :show-close="true"
      @closeDialog="closeDialog"
    >
      <div class="wrap">
        <ul class="left">
          <li>
            <label>品牌名称：</label>
            <span>{{infoObj.name||''}}</span>
          </li>
          <li>
            <label>品牌企业：</label>
            <span>{{infoObj.company_name||''}}</span>
          </li>
          <li>
            <label>品牌编号：</label>
            <span>{{infoObj.number||''}}</span>
          </li>
          <li>
            <label>时间：</label>
            <span>{{infoObj.create_time||''}}</span>
          </li>
        </ul>
        <div class="right">
          <img :src="imageUrl+infoObj.imgUrl" @error="e => { e.target.src = defaultImg }" alt />
          <p>{{infoObj.name||''}}</p>
        </div>
      </div>
    </base-dialog>
  </div>
</template>
<script>
import BaseDialog from '@/components/dialog/baseDialog' //弹框
// import { imageUrl } from '@/utils/config'
export default {
  components: { BaseDialog },
  data() {
    return {
      dialogFlag: false,
      infoObj: {},
      imageUrl: sysUrl,
      defaultImg: require('@/assets/images/default_img.png'),
    }
  },
  mounted() {},
  methods: {
    closeDialog() {
      //关闭弹框
      this.dialogFlag = false
    },
    show(obj) {
      //显示
      this.infoObj = obj
      this.dialogFlag = true
    },
  },
}
</script>

<style lang='scss' scoped>
.wrap {
  // border: 1px solid #f00;
  // height: 100%;
  display: flex;
  padding: 50px;
  box-sizing: border-box;
  align-items: center;
  .left {
    // border: 3px solid #f00;
    flex: 1;
    padding-left: 50px;
    box-sizing: border-box;
    li {
      font-size: 20px;
      margin-top: 30px;
      padding: 0 10px;
      box-sizing: border-box;
      label {
        color: #fff;
        display: inline-block;
        width: 100px;
        text-align: right;
      }
      span {
        color: #10c4ff;
      }
    }
    li:nth-child(1) {
      margin-top: 0;
    }
  }
  .right {
    flex: none;
    // margin-left: 65px;
    // border: 3px solid #0f0;
    width: 425px;
    height: 320px;
    font-size: 0;
    background: url('~@/assets/images/dialog/qyxx_border.png') no-repeat
      center/100% 100%;
    padding: 15px 10px 10px 18px;
    box-sizing: border-box;
    position: relative;
    text-align: center;
    p {
      position: absolute;
      left: 18px;
      right: 10px;
      bottom: 10px;
      font-size: 14px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      background-color: rgba(0, 0, 0, 0.57);
    }
    img {
      max-width: 100%;
      height: 100%;
    }
  }
}
::v-deep .base_dialog .shade {
  z-index: 103;
}
</style>