<template>
  <div class="jpjs">
    <div class="left">
      <map-chart ref="brandMapRef" height="880px" :chart-option="chartOption" @selectMarker="markerSelect"></map-chart>

      <div class="pub_legend">
        <div class="legend_title">图例</div>
        <ul class="legend_list">
          <li v-for="item in legendData" :key="item.lablename">
            <el-checkbox v-model="item.check" class="pub_checkbox" @change="selectChange($event,item)"></el-checkbox>
            <img :src="item.icon" alt=""> {{ item.lablename }}
          </li>
          <!-- <li>
              <el-checkbox v-model="checked" class="pub_checkbox"></el-checkbox>
              <img src="@/assets/images/cygk/zdxm_icon.png" alt=""> 化肥企业
          </li>
          <li>
              <el-checkbox v-model="checked" class="pub_checkbox"></el-checkbox>
              <img src="@/assets/images/cygk/zdxm_icon.png" alt=""> 种子种苗
          </li>
          <li>
              <el-checkbox v-model="checked" class="pub_checkbox"></el-checkbox>
              <img src="@/assets/images/cygk/zdxm_icon.png" alt=""> 农药销售
          </li>
          <li>
              <el-checkbox v-model="checked" class="pub_checkbox"></el-checkbox>
              <img src="@/assets/images/cygk/zdxm_icon.png" alt=""> 生产器械
          </li>
          <li>
              <el-checkbox v-model="checked" class="pub_checkbox"></el-checkbox>
              <img src="@/assets/images/cygk/zdxm_icon.png" alt=""> 检验检测
          </li> -->
        </ul>
      </div>
    </div>
    <div class="right">
      <div class="top">
        <div class="info-item" v-for="item in info" :key="item.name">
          <span>{{ item.name }}</span>
          <div class="text-wrapper" v-for="num in item.count" :key="num">
            <div class="num">{{ num }}</div>
          </div>
          <span>个</span>
        </div>
      </div>
      <div class="bottom">
        <div class="left">
          <div class="inner-top pub_border">
            <div class="pub_title" @click="chartShow">三标数据</div>
            <el-table :data="tableData" stripe class="pub_table" height="340px">
              <el-table-column prop="cityName" label="地市" align="center"></el-table-column>
              <el-table-column prop="green" label="绿色标识" align="center">
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="showBrandList(scope.row, '绿色认证')">{{ scope.row.green }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="organic" label="有机食品" align="center">
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="showBrandList(scope.row, '有机食品')">{{ scope.row.organic }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="region" label="地理标识" align="center">
                <template slot-scope="scope">
                  <div style="cursor: pointer" @click="showBrandList(scope.row, '地理标识')">{{ scope.row.region }}</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="inner-bottom pub_border">
            <div class="pub_title">三标展示</div>
            <div class="content">
              <div class="wrap">
                <img @click="arrowClick('left')" src="./../../assets/images/left_arrow.png" class="arrow left_arrow"
                  alt />
                <el-carousel ref="cardShow" type="card" height="230px" :autoplay="true" arrow="never">
                  <el-carousel-item v-for="item in brandImage" :key="item.id">
                    <div class="inner" @click="brandShow(item)">
                      <h3>{{ item.name }}</h3>
                      <img :src="`${imageUrl}${item.img_url}`" />
                      <p>{{ item.company_name }}</p>
                    </div>
                  </el-carousel-item>
                </el-carousel>
                <img @click="arrowClick('right')" src="./../../assets/images/right_arrow.png" class="arrow right_arrow"
                  alt />
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="inner-top pub_border">
            <div class="pub_title">推荐菜王</div>
            <div class="recommend">
              <div class="item" :class="`item${index + 1}`" v-for="(item, index) in plantHotList" :key="item.id"
                @click="handleClick(item)">
                <div class="title">{{ item.title }}</div>
                <div class="image">
                  <img :src="`${imageUrl}${item.img}`" />
                </div>
              </div>
            </div>
          </div>
          <div class="inner-bottom pub_border">
            <div class="pub_title">品牌推广</div>
            <el-scrollbar class="list">
              <div class="list-item" v-for="item in promotionList" :key="item.id" @click="handleClick(item)">
                <img src="./../../assets/images/cyfw_nz.png" alt />
                <div class="title">{{ item.title }}</div>
                <div class="date">{{ item.publishTime }}</div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </div>
    <Enterprise-info ref="enterpriseRef"></Enterprise-info>
    <article-detail ref="articleRef"></article-detail>
    <base-dialog width="1200px" height="500px" label="三标数据" :show-close="true" v-show="chartFlag"
      @closeDialog="chartFlag = false">
      <div id="bar" style="width: 1200px;height: 450px"></div>
    </base-dialog>
    <!-- 品牌展示 -->
    <brand-detail ref="brandDetail"></brand-detail>
    <!-- 品牌列表 -->
    <brand-list ref="brandList"></brand-list>
  </div>
</template>

<script>
import mapChart from '../../components/map/mapCopy'
import * as api from '@/api/jpjs'
import { ERR_OK } from '@/utils/config'
import EnterpriseInfo from '@/components/enterprise' //企业信息
import ArticleDetail from '@/components/articleDetail' //图文详情
import BaseDialog from '@/components/dialog/baseDialog' //弹框
import BrandDetail from '@/components/brandDetail' //品牌展示
import BrandList from '@/components/brandList' //品牌列表

export default {
  name: 'Jpjs',
  components: {
    mapChart,
    EnterpriseInfo,
    ArticleDetail,
    BaseDialog,
    BrandDetail,
    BrandList,
  },
  data() {
    return {
      imageUrl: sysUrl,
      info: [
        {
          name: '绿色食品',
          count: '',
        },
        {
          name: '有机食品',
          count: '',
        },
        {
          name: '地理标识',
          count: '',
        },
      ],
      legendData: [
        {lablename:'茄子', icon: require('@/assets/images/jpjs/qiezi.png'), check:true,children: []},
        {lablename:'西瓜', icon: require('@/assets/images/jpjs/xigua.png'), check:true,children: []},
        {lablename:'辣椒', icon: require('@/assets/images/jpjs/lajiao.png'), check:true,children: []},
        {lablename:'豆角', icon: require('@/assets/images/jpjs/doujiao.png'), check:true,children: []},
        {lablename:'橄榄', icon: require('@/assets/images/jpjs/ganlan.png'), check:true,children: []},
        {lablename:'芹菜', icon: require('@/assets/images/jpjs/qincai.png'), check:true,children: []},
        {lablename:'洋葱', icon: require('@/assets/images/jpjs/yangcong.png'), check:true,children: []},
        {lablename:'菠菜', icon: require('@/assets/images/jpjs/bocai.png'), check:true,children: []},
        {lablename:'西红柿', icon: require('@/assets/images/jpjs/xihongshi.png'), check:true,children: []},
        {lablename:'黄瓜', icon: require('@/assets/images/jpjs/huanggua.png'), check:true,children: []},
        {lablename:'胡萝卜', icon: require('@/assets/images/jpjs/huluobo.png'), check:true,children: []},
        {lablename:'白萝卜', icon: require('@/assets/images/jpjs/bailuobo.png'), check:true,children: []},
        {lablename:'生姜', icon:require('@/assets/images/jpjs/shengjiang.png'), check:true,children: []},
        {lablename:'白菜', icon:require('@/assets/images/jpjs/baicai.png'), check:true,children: []},
        {lablename:'其他', icon:require('@/assets/images/ssjg_marker.png'), check:true,children: []},
      ],
      tableData: [],
      brandImage: [],
      promotionList: [],
      plantHotList: [],
      chartOption: {
        layoutSize: '125%',
        tooltip: (params) => {
          let data = params.data
          let html = `<div style="padding:0;width: 280px;height: 90px;background: rgba(2,18,81,0.68);">
                            <div style="width: 254px;height: 23px;line-height: 23px;text-indent: 1em;font-size: 13px;color: #fff;background: linear-gradient(90deg,rgba(45,125,255,1), rgba(45,125,255,0))">${data.company_name
            }</div>
                            <div style="margin-left: 1em;line-height: 30px"><span>品牌：<span style="color:#0CFED9;font-size: 18px">${data.name || ''
            }</span></span></div>
                            <div style="margin-left: 1em;line-height: 30px"><span>品牌类型：<span style="color:#0CFED9;font-size: 18px">${data.brand_type || ''
            }</span></span></div>
                        </div>`
          return html
        },
      },
      chartFlag: false,
    }
  },
  mounted() {
    this.init()
    this.initBar()
  },
  methods: {
    // 初始化数据
    async init() {
      await this.getBrandInfo()
      await this.getBrandList()
      await this.getBrandMapList()
      await this.getBrandImageList()
      await this.getBrandPromotionList()
      await this.getPlantHotList()
    },
    // 品牌类型的总数
    getBrandInfo() {
      return api.getBrandInfo().then((res) => {
        if (res.code === ERR_OK) {
          // 绿色
          this.info[0].count = res.data.green.toString()
          // 有机
          this.info[1].count = res.data.organic.toString()
          // 地域
          this.info[2].count = res.data.region.toString()
        }
      })
    },
    // 城市-品牌种类统计列表
    getBrandList() {
      return api.getBrandList().then((res) => {
        if (res.code === ERR_OK) {
          this.tableData = res.data
        }
      })
    },
    // 表格列点击
    showBrandList(obj, type) {
      this.$refs.brandList.show({
        brandType: type,
        areaCode: obj.city,
      })
    },
    selectChange(val,row){
      console.log( this.legendData,'selectChange')
      var data = this.legendData?.filter(item => item.check == true)?.map(item => item.children)?.flat(Infinity)??[]
      console.log(data)
      this.$refs.brandMapRef.addMarker(data.filter(item => ((item?.data??[{}])[0]?.longitude??null) && ((item?.data??[{}])[0]?.latitude??null)))
    },
    // 获取上图的列表
    getBrandMapList() {
      return api.getBrandMapList().then((res) => {
        if (res.code === ERR_OK) {
          // console.log(res, 'lllllllllllllllllllll');
          let marker = []
          const imgMap = {
            '茄子': require('@/assets/images/jpjs/qiezi.png'),
            '西瓜': require('@/assets/images/jpjs/xigua.png'),
            '辣椒': require('@/assets/images/jpjs/lajiao.png'),
            '豆角': require('@/assets/images/jpjs/doujiao.png'),
            '橄榄': require('@/assets/images/jpjs/ganlan.png'),
            '芹菜': require('@/assets/images/jpjs/qincai.png'),
            '洋葱': require('@/assets/images/jpjs/yangcong.png'),
            '菠菜': require('@/assets/images/jpjs/bocai.png'),
            '西红柿': require('@/assets/images/jpjs/xihongshi.png'),
            '黄瓜': require('@/assets/images/jpjs/huanggua.png'),
            '胡萝卜': require('@/assets/images/jpjs/huluobo.png'),
            '白萝卜': require('@/assets/images/jpjs/bailuobo.png'),
            '生姜': require('@/assets/images/jpjs/shengjiang.png'),
            '白菜': require('@/assets/images/jpjs/baicai.png'),
          };
          var data = Object.keys(res.data).map(item => res.data[item])?.flat(Infinity)??[]
          console.log(data,res.data)
          var data1 = data?.filter(item => (item.name == '茄子' || item.name == '西瓜' || item.name == '辣椒'
                  || item.name == '豆角' || item.name == '橄榄' || item.name == '芹菜' || item.name == '洋葱'
                  || item.name == '菠菜' || item.name == '西红柿' || item.name == '黄瓜' || item.name == '胡萝卜'
                  || item.name == '白萝卜' || item.name == '生姜' || item.name == '白菜'))?.map(item => {
            var row = this.legendData?.find(ite => ite.lablename == item.name)??null
            if(row) {
              console.log(item,'company_name')
              let markImg = imgMap[item.name] || require('@/assets/images/ssjg_marker.png');
              var list = {
                name: item.company_name,
                names: item.company_name,
                url: `image://` + markImg,
                size: [30, 30],
                data: [
                  {
                    value: [item.longitude, item.latitude],
                    //                         value: [119.596224, 39.943458],
                    ...item,
                    names: item.company_name,
                  },
                ],
              }
              row.children.push(list)
              return list
            }
          })??[]
          var data2 =  data?.filter(item => item.name != '茄子')?.filter(item => item.name != '西瓜')?.filter(item =>  item.name != '辣椒')
                  ?.filter(item =>  item.name != '豆角' )?.filter(item =>  item.name != '橄榄' )?.filter(item =>  item.name != '芹菜')
                  ?.filter(item =>  item.name != '洋葱')?.filter(item =>  item.name != '菠菜' )?.filter(item =>  item.name != '西红柿' )
                  ?.filter(item =>  item.name != '黄瓜' )?.filter(item =>  item.name != '胡萝卜')?.filter(item =>  item.name != '白萝卜' )?.filter(item =>  item.name != '生姜' )?.filter(item =>  item.name != '白菜')?.map(item => {
            var row = this.legendData?.find(ite => ite.lablename == '其他')??null
            if(row) {
              let markImg = require('@/assets/images/ssjg_marker.png');
              var list = {
                name: item.company_name,
                names: item.company_name,
                url: `image://` + markImg,
                size: [30, 30],
                data: [
                  {
                    value: [item.longitude, item.latitude],
                    //                         value: [119.596224, 39.943458],
                    ...item,
                    names: item.company_name,
                  },
                ],
              }
              row.children.push(list)
              return list
            }
          })??[]
          console.log(data2,'55555555')
          this.$refs.brandMapRef.addMarker([...data1,...data2].filter(item => ((item?.data??[{}])[0]?.longitude??null) && ((item?.data??[{}])[0]?.latitude??null)),'2')

          // data?.
          /*Object.keys(res.data).forEach((key) => {
            let data = res.data[key]
            console.log(data,[key],'999999999')
            data.forEach((item) => {
              let markImg = imgMap[item.name] || require('@/assets/images/ssjg_marker.png');
              let obj = {
                name: key,
                // url: `image://${require('@/assets/images/ssjg_marker.png')}`,
                url: `image://` + markImg,
                size: [30, 30],
                data: [
                  {
                    value: [item.longitude, item.latitude],
                    //                         value: [119.596224, 39.943458],
                    ...item,
                  },
                ],
              }
              marker.push(obj)
            })
          })
          if (marker.length) {
            this.$refs.brandMapRef.addMarker(marker)
          }*/
        }
      })
    },
    isNull (row) {
      return Boolean (typeof row === 'undefined' || row == null || row === '')
    },
    // 品牌展示
    getBrandImageList() {
      return api.getBrandImageList().then((res) => {
        if (res.code === ERR_OK) {
          this.brandImage = res.data
        }
      })
    },
    // 品牌推广
    getBrandPromotionList() {
      return api.getBrandPromotionList().then((res) => {
        if (res.code === ERR_OK) {
          this.promotionList = res.data
        }
      })
    },
    // 推荐菜王
    getPlantHotList() {
      return api.getPlantHotList().then((res) => {
        if (res.code === ERR_OK) {
          this.plantHotList = res.rows
        }
      })
    },
    arrowClick(val) {
      //上下切换
      if (val === 'right') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }
    },
    // marker点击
    markerSelect(obj) {
      this.$refs.enterpriseRef.show(obj)
    },
    // 推荐菜王点击、推荐点击
    handleClick(obj) {
      this.$refs.articleRef.show({
        imgUrl: `${this.imageUrl}${obj.img}`,
        title: obj.title,
        content: obj.content,
        createtime: obj.publishTime,
      })
    },
    initBar() {
      //三标数据
      this.bar = this.$echarts.init(document.getElementById('bar'))
      let legendData = ['绿色食品', '有机食品', '地理标识']
      let fontColor = '#B9C3D3'
      let option = {
        backgroundColor: 'transparent',
        grid: {
          left: '5%',
          right: '2%',
          top: '12%',
          bottom: '15%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            lineStyle: {
              color: fontColor,
            },
          },
        },
        legend: {
          data: legendData,
          right: '8%',
          top: '2%',
          textStyle: {
            color: fontColor,
          },
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: fontColor,
            },
          },
          offset: 0,
          axisTick: {
            show: false,
            alignWithLabel: true,
            lineStyle: {
              color: fontColor,
            },
          },
          axisLabel: {
            fontSize: 16,
            color: fontColor,
          },
          data: [],
        },
        yAxis: {
          name: '个数',
          nameTextStyle: {
            color: fontColor,
          },
          type: 'value',
          minInterval: 1,
          min: 0,
          axisLine: {
            show: true,
            lineStyle: {
              color: fontColor,
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dotted',
              color: fontColor,
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 16,
            color: fontColor,
          },
          boundaryGap: ['20%', '20%'],
        },
        series: [
          {
            name: legendData[0],
            type: 'bar',
            smooth: true,
            symbol: 'none',
            showSymbol: false,
            symbolSize: 8,
            barWidth: 10,
            itemStyle: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                type: 'linear',
                global: false,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(0,168,255,1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(0,168,255,0)',
                  },
                ],
              },
            },
            data: [],
          },
          {
            name: legendData[1],
            type: 'bar',
            smooth: true,
            symbol: 'none',
            showSymbol: false,
            symbolSize: 8,
            barWidth: 10,
            itemStyle: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                type: 'linear',
                global: false,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(90,231,153,1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(90,231,153,0)',
                  },
                ],
              },
            },
            data: [],
          },
          {
            name: legendData[2],
            type: 'bar',
            smooth: true,
            symbol: 'none',
            showSymbol: false,
            symbolSize: 8,
            barWidth: 10,
            itemStyle: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                type: 'linear',
                global: false,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,186,0,1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(255,186,0,0)',
                  },
                ],
              },
            },
            data: [],
          },
        ],
      }
      this.bar.setOption(option)
    },
    barReload(data) {
      this.bar.setOption({
        xAxis: {
          data: data.map((item) => item.cityName),
        },
        series: [
          {
            data: data.map((item) => item.green),
          },
          {
            data: data.map((item) => item.organic),
          },
          {
            data: data.map((item) => item.region),
          },
        ],
      })
    },
    chartShow() {
      this.chartFlag = true
      this.barReload(this.tableData)
    },
    brandShow(obj) {
      //查看品牌展示
      let param = {
        imgUrl: obj.img_url, //时间
        ...obj,
      }
      this.$refs.brandDetail.show(param)
    },
  },
}
</script>
<style lang='scss' scoped>
.jpjs {
  box-sizing: border-box;
  padding: 0 70px;
  display: flex;

  .left {
    width: 550px;
    margin-right: 40px;
    position: relative;
  }

  .right {
    flex: 1;

    .top {
      height: 120px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 140px;

      .info-item {
        display: flex;
        align-items: center;

        >span:first-child {
          color: #fff;
          font-size: 22px;
          margin-right: 15px;
        }

        .text-wrapper {
          width: 34px;
          height: 46px;
          text-align: center;
          line-height: 46px;
          font-size: 32px;
          background: url('./num-bg.png') no-repeat center/cover;
          margin-right: 4px;

          .num {
            background-image: linear-gradient(0deg, #fed471 0%, #fdf79e 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        >span:last-child {
          color: #fff;
          font-size: 18px;
          margin-left: 15px;
        }
      }
    }

    .bottom {
      display: flex;

      .left {
        width: 650px;
        margin-right: 30px;
      }

      .right {
        flex: 1;

        .inner-top {
          .recommend {
            position: relative;
            box-sizing: border-box;

            .item {
              display: flex;
              flex-direction: column;
              position: absolute;
              left: 0;
              top: 16px;
              cursor: pointer;

              .title {
                font-size: 20px;
                background: url('~@/assets/images/jppp_bg.png') no-repeat center/100% 100%;
                margin-bottom: 5px;
                height: 30px;
                line-height: 30px;
                text-align: center;
              }

              .image {
                width: 180px;
                height: 130px;
                background: rgba(0, 174, 255, 0.2);
                border: 1px solid #20a8ff;
                margin: 5px 0 0;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              img {
                width: 100%;
                height: 110px;
              }

              &.item2 {
                left: 160px;
                top: 30px;
                transform: scale(0.48);
              }

              &.item3 {
                left: 300px;
                top: 15px;
                transform: scale(0.67);
              }

              &.item4 {
                left: -34px;
                top: 170px;
                transform: scale(0.62);
              }

              &.item5 {
                left: 90px;
                top: 170px;
                transform: scale(0.48);
              }

              &.item6 {
                left: 210px;
                top: 150px;
                transform: scale(0.56);
              }

              &.item7 {
                left: 330px;
                top: 130px;
                transform: scale(0.48);
              }
            }
          }
        }
      }

      .left,
      .right {
        .inner-top {
          height: 400px;
          padding: 12px 20px;
          box-sizing: border-box;
        }

        .inner-bottom {
          margin-top: 20px;
          height: 320px;

          .content {
            flex: 1;
            padding: 10px 50px;
            box-sizing: border-box;
            overflow: hidden;

            .wrap {
              background: url('~@/assets/images/jppp_border.png') no-repeat center/100% 100%;
              height: 260px;
              padding: 0 50px;
              box-sizing: border-box;
              position: relative;

              .inner {
                text-align: center;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                background: #012c5f;
                padding-top: 10px;

                h3 {
                  font-size: 20px;
                  background: url('~@/assets/images/jppp_bg.png') no-repeat center/100% 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  height: 30px;
                  line-height: 30px;
                }

                img {
                  width: auto;
                  max-width: 100%;
                  height: 140px;
                  margin: 15px 0 0;
                }

                p {
                  font-size: 16px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              .arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
              }

              .left_arrow {
                left: 10px;
              }

              .right_arrow {
                right: 10px;
              }

              ::v-deep.el-carousel__button {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #25b0ff;
              }

              ::v-deep .el-carousel__indicators--horizontal {
                display: none;
              }

              ::v-deep .el-carousel__mask {
                background-color: #012c5f;
              }
            }
          }

          .list {
            height: 220px;
            margin: 20px;

            .list-item {
              height: 40px;
              display: flex;
              justify-content: space-around;
              align-items: center;
              font-size: 16px;
              cursor: pointer;

              &:nth-child(even) {
                background: rgba(0, 66, 151, 0.54);
              }

              img {
                margin-left: 10px;
              }

              .date {
                color: #02cafd;
                width: 100px;
              }

              .title {
                flex: 1;
                margin-left: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            ::v-deep.el-scrollbar__wrap {
              overflow-x: hidden;
            }
          }
        }
      }

      .pub_border {
        background: linear-gradient(180deg,
            rgba(14, 47, 82, 0),
            rgba(14, 47, 82, 0.76));
      }
    }
  }
}

.pub_legend {
  position: absolute;
  right: 40px;
  bottom: 60px;

  .legend_list {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;

    >li {
      font-size: 16px;
      // border: 1px solid #0f0;
      height: 30px;
      display: flex;
      align-items: center;
      // padding-right: 0px;
      box-sizing: border-box;
      cursor: pointer;

      .pub_checkbox {
        margin-right: 10px;
      }

      img {
        margin-right: 10px;
        width: 21px;
      }
    }

    // >li:nth-child(7){
    //     img{
    //         position: relative;
    //         left: 5px;
    //     }
    // }
  }
}
</style>
