<template>
  <div class="map-wrapper" :style="{ 'height': height }">
    <div class="back" v-show="hasDrop && drop" @click="back"></div>
    <div class="map" id="Map"></div>
  </div>
</template>

<script type="text/ecmascript-6">
  import areaCode from '../../../public/config/area.json'
  import countyCode from '../../../public/config/county.json'
  import hebei from '@/assets/map/map-hebei.json'
  // import areas from '@/assets/map/areas.json'
  import county from '@/assets/map/map-county.json'
  import bd from '@/assets/map/bd.json'
  import cd from '@/assets/map/cd.json'
  import cz from '@/assets/map/cz.json'
  import hd from '@/assets/map/hd.json'
  import hs from '@/assets/map/hs.json'
  import lf from '@/assets/map/lf.json'
  import qhd from '@/assets/map/qhd.json'
  import sjz from '@/assets/map/sjz.json'
  import ts from '@/assets/map/ts.json'
  import xt from '@/assets/map/xt.json'
  import zjk from '@/assets/map/zjk.json'

  const geoJSON = {
    保定市: bd,
    承德市: cd,
    沧州市: cz,
    邯郸市: hd,
    衡水市: hs,
    廊坊市: lf,
    秦皇岛市: qhd,
    石家庄市: sjz,
    唐山市: ts,
    邢台市: xt,
    张家口市: zjk,
  }

  let countyJson = {
    type: 'map',
    map: 'county',
    aspectScale: 0.88,
    roam: false,
    scaleLimit: {
      min: 1,
    },
    z: 3,
    animationDurationUpdate: 0,
    label: {
      normal: {
        show: false,
        textStyle: {
          color: '#fff',
          fontSize: '17',
        },
      },
      emphasis: {
        textStyle: {
          color: '#fff',
          fontSize: '17',
        },
      },
    },
    itemStyle: {
      normal: {
        borderColor: 'rgba(162,255,0,0.61)',
        areaColor: 'rgba(162,255,0,0.61)',
      },
    },
    emphasis: {
      itemStyle: {
        borderColor: 'rgba(255,117,63,0.63)',
        areaColor: 'rgba(255,117,63,0.63)',
      },
    },
  }


  export default {
    props: {
      hasDrop: {
        type: Boolean,
        default: false,
      },
      height: {
        type: String,
        default: '664px',
      },
      chartOption: {
        type: Object,
        default: () => {
          return {}
        },
      },
      hasCounty: {
        type: Boolean,
        default: false,
      },
      hasZoom: {
        type: Boolean,
        default: false,
      },
      geoData: {
        type: Array,
        default: () => [],
      },
      countySelect: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        drop: false,
        dataList: [],
        mapDataList:[],
        markersData: {}
      }
    },
    mounted() {
      this.initMap()
    },
    methods: {
      initMap() {
        this.$echarts.registerMap('河北', hebei)
        this.$echarts.registerMap('county', county)
        this.mapChart = this.$echarts.init(document.getElementById('Map'))
        this.option = {
          tooltip: {

            enterable: true,
          },
          geo: [
            {
              map: '河北',
              layoutCenter: ['50%', '50%'],
              layoutSize: this.chartOption.layoutSize || '100%',
              label: {
                normal: {
                  show: true,
                  textStyle: {
                    color: '#fff',
                    fontSize: '17',
                    backgroundColor: 'rgba(0,96,255,0.5)',
                    lineHeight: 32,
                    padding: [0, 8],
                    borderWidth: 1,
                    borderColor: 'rgba(0,96,255,1)',
                  },
                },
                emphasis: {
                  textStyle: {
                    color: '#fff',
                    fontSize: '17',
                    backgroundColor: 'rgba(255,117,63,0.63)',
                    lineHeight: 32,
                    padding: [0, 8],
                    borderWidth: 1,
                    borderColor: 'rgba(255,117,63,1)',
                  },
                },
              },
              roam: true, //是否允许缩放
              aspectScale: 0.8,
              scaleLimit: {
                min: 1,
              },
              selectedMode: 'single',
              itemStyle: {
                normal: {
                  borderColor: '#B5E9FB',
                  borderWidth: 2,
                  areaColor: {
                    type: 'radial',
                    x: 0.6,
                    y: 0.6,
                    r: 3,
                    colorStops: [
                      {
                        offset: 0,
                        color: 'rgba(95,197,255, 0)', // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: 'rgba(95,197,255, 1)', // 100% 处的颜色
                      },
                    ],
                    globalCoord: true,
                  },
                  shadowColor: 'rgb(21,171,246)',
                  shadowOffsetX: 0,
                  shadowOffsetY: 6,
                  shadowBlur: 4,
                },
              },
              emphasis: {
                itemStyle: {
                  borderColor: 'rgba(229,185,8, 1)',
                  borderWidth: 2,
                  areaColor: {
                    type: 'radial',
                    x: 0.6,
                    y: 0.6,
                    r: 3,
                    colorStops: [
                      {
                        offset: 0,
                        color: 'rgba(95,197,255, 0)', // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: 'rgba(95,197,255, 1)', // 100% 处的颜色
                      },
                    ],
                    globalCoord: true,
                  },
                  shadowColor: 'rgba(229,185,8, 1)',
                  shadowOffsetX: 2,
                  shadowOffsetY: 4,
                  shadowBlur: 4,
                },
              },
              select: {
                itemStyle: {
                  borderColor: 'rgba(229,185,8, 1)',
                  borderWidth: 2,
                  areaColor: {
                    type: 'radial',
                    x: 0.6,
                    y: 0.6,
                    r: 3,
                    colorStops: [
                      {
                        offset: 0,
                        color: 'rgba(95,197,255, 0)', // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: 'rgba(95,197,255, 1)', // 100% 处的颜色
                      },
                    ],
                    globalCoord: true,
                  },
                  shadowColor: 'rgba(229,185,8, 1)',
                  shadowOffsetX: -2,
                  shadowOffsetY: 4,
                  shadowBlur: 10,
                },
              },
              tooltip:
                      {
                        trigger:"item",
                        triggerOn:"mousemove|click",
                        enterable: true,
                        show: true,
                        position: function (point, params, dom, rect, size) {
                          return [point[0], point[1]];
                        },
                        formatter: (params) => {
                          if((params?.data?.list?.length??0) > 1) {
                            let data = params.data
                            let html = ''
                            html += `<div style="padding:0;width: 280px;height: 120px;background: rgba(2,18,81,0.68);overflow-y: auto;overflow-x: hidden" class="namesss">`
                            data.list.forEach((item,index) => {
                              var a = data.id + '_'+item.id
                              html += `<div style="margin-left: 1em;line-height: 30px;display: flex;cursor: pointer;
    justify-content: space-between;"  onclick="changeId('${a}')">${item.names || item.name}</span> <span>查询</span></div>`
                            })
                            html += `</div>`

                            return html
                          } else {

                            return this.chartOption.tooltip
                                    ? this.chartOption.tooltip(params)
                                    : params.name
                          }
                        },
                      },

            },
          ],
          series: [],
        }

        // 检查是否包含指定区县地图
        if (this.hasCounty) {
          this.option.geo.push(countyJson)
        }
        //            this.setGeoSetting(this.geoData)
        this.mapChart.setOption(this.option, true)
        this.mapChart.on('click', (params) => {
          //                this.clickStatus = false
          console.log('click', params)
          const callback = () => {
            //                    if (this.clickStatus !== false) return
            if (params.componentType === 'geo') {
              if (
                      params.region.selected ||
                      params.region.name !== this.lastName
              ) {
                const key =
                        this.option.geo[0].map === '河北'
                                ? this.lastName
                                : this.option.geo[0].map
                if (
                        countyCode[key] !== undefined &&
                        countyCode[key][params.name] &&
                        !this.countySelect
                )
                  return
                const code =
                        areaCode[params.name] || countyCode[key][params.name] || ''
                this.$emit('selectCity', {
                  parent: this.option.geo[0].map,
                  name: params.name,
                  level: areaCode[params.name] ? 2 : 3,
                  code: code,
                })
                this.lastName = params.region.name
                if (!this.hasDrop) return
                //                            this.clickStatus = true
                if (geoJSON[params.name]) {
                  this.$echarts.registerMap(params.name, geoJSON[params.name])
                  this.option.geo[0].map = params.name
                  // 包含则下钻去掉
                  if (this.hasCounty) {
                    this.option.geo.length = 1
                  }
                  this.mapChart.setOption(this.option, true)
                  this.drop = true
                }
              } else {
                if (this.option.geo[0].map === '河北') {
                  this.$emit('selectCity', {
                    parent: '',
                    name: '河北省',
                    level: 1,
                    code: '',
                  })
                  this.lastName = '河北省'
                } else {
                  this.$emit('selectCity', {
                    parent: '河北省',
                    name: this.option.geo[0].map,
                    level: 2,
                    code: areaCode[this.option.geo[0].map],
                  })
                  this.lastName = this.option.geo[0].map
                }
              }
            } else if (params.seriesType === 'scatter') {
              this.$emit('selectMarker', params.data)
            }
          }
          //                setTimeout(callback, 500)
          callback()
        })
        //            this.mapChart.on('dblclick', (params) => {
        //                if (!this.hasDrop) return
        //                this.clickStatus = true
        //                if (geoJSON[params.name]) {
        //                    this.$echarts.registerMap(params.name, geoJSON[params.name])
        //                    this.option.geo[0].map = params.name
        //                    // 包含则下钻去掉
        //                    if (this.hasCounty) {
        //                        this.option.geo.length = 1
        //                    }
        //                    this.mapChart.setOption(this.option, true)
        //                    this.drop = true
        //                }
        //            })
        this.mapChart.on('georoam', (params) => {
          if (!this.hasCounty) return
          let option = this.mapChart.getOption()
          if (params.zoom !== null && params.zoom !== undefined) {
            this.addMarker(this.mapDataList)
            option.geo[1].zoom = option.geo[0].zoom
            option.geo[1].center = option.geo[0].center
          } else {
            option.geo[1].center = option.geo[0].center
          }
          this.mapChart.setOption(option, true)
        })
        this.mapChart.on('mousemove', (params) => {
          if (params.componentType === 'geo') {
            this.$emit('geoMousemove', {
              pageX: params.event.event.pageX + 50,
              pageY: params.event.event.pageY,
              name: params.name,
            })
          }
        })
        let that = this
        function changeId(row) {
          console.log(row,'changeId')
          var data = that?.option?.series?.find(item => item.datas.id == row.split('_')[0])?.children?.find(item => item.datas.id == row.split('_')[1])?.datas??{}
          if(data?.id) {
            that.$emit('selectMarker', data)
          }
        }
        window.changeId = changeId
      },
// 地图数据点聚合
      mapData(list, distance) {
        let arrMaplist = [],arrMaplist1 = [],arrMaplist2 = [], lngIndex = true, latIndex = 0;
        var data = []
        var data1 = []
        data1 = list.filter(items =>data.every(y =>y != items))
        arrMaplist1 = this.shuzu(data1,data,distance,list.length)
        this.dataList.forEach(data => {
          // data[0].children = data
          var datas = data[0].data[0].list
          arrMaplist.push({
            ...data[0],
            total: data.length,
            children: data,
            data: [{
              ...data[0].data[0],
              list: data.map(item => item.data[0])
            }]
          })
        })
        return arrMaplist
      },
      shuzu(row,data,distance,length) {
        var a = 0
        data.forEach(item => {
          a+= item.length
          if(a >= length) {
            this.dataList = data
            return data
          }
        })
        if(row.length>0 ) {
          var data2 =row.filter(items => Math.abs(Number(JSON.parse(JSON.parse(row[0].data[0].longitude))) - Number(JSON.parse(JSON.parse(items.data[0].longitude))))<= distance && Math.abs(Number(JSON.parse(JSON.parse(row[0].data[0].latitude))) - Number(JSON.parse(JSON.parse(items.data[0].latitude)))) <= distance)
          var data1 =  row.filter(items =>data2.every(y =>y != items))
          data = [...data,data2]
          this.shuzu(data1,data,distance,length)
        }
        return data
      },

      groupBy(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
          var key = obj[property];
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});
      }
      ,
      addMarker(markers) {
        let series = []
        let series1 = []
        this.mapChart.clear()
        this.mapDataList = markers
        // this.mapDataList
        if (this.mapDataList.length>0) {
            var data = this.groupBy(this.mapDataList,'url')
            this.mapDataList?.filter(item => item.type == 1).forEach((marker) => {
              let { data, name, url, size } = marker
              let markers = {
                name,
                type: 'scatter',
                leixing: '----',
                type11: marker.type,
                coordinateSystem: 'geo',
                symbol: url,
                symbolSize: size,
                itemStyle: {
                  opacity: 1,
                },
                data,
                datas: data[0],
                z: 200,
                zLevel: 200,
              }
              series.push(markers)
            })
            this.mapDataList?.filter(item => item.type == 2).forEach((marker) => {
              let { data, name, url, size } = marker
              let markers = {
                name,
                type: 'scatter',
                leixing: '----',
                type11: marker.type,
                coordinateSystem: 'geo',
                symbol: url,
                symbolSize: size,
                itemStyle: {
                  opacity: 1,
                },
                data,
                datas: data[0],
                z: 200,
                zLevel: 200,
              }
              series1.push(markers)
            })
            var data1 = Object.keys(data).map(keys => {
              return data[keys].map(marker => {
                let { data, name, url, size } = marker
                let markers = {
                  name,
                  type: 'scatter',
                  leixing: '----',
                  type11: marker.type,
                  coordinateSystem: 'geo',
                  symbol: url,
                  symbolSize: size,
                  itemStyle: {
                    opacity: 1,
                  },
                  data,
                  datas: data[0],
                  z: 200,
                  zLevel: 200,
                }
                return markers
              })
            })?.map(datas => {
              var data_a = this.mapData(datas, 0.5)?.map(item => {
                // item.data[0].children = item.children
                item.label= {
                  show: true,
                  position: 'outside',
                  color: '#fff',
                  lineHeight: 20,
                  fontSize: 18,
                  backgroundColor: '#e7642f',
                  borderColor: '#e7642f',
                  borderRadius: 10,
                  borderWidth: 1,
                  formatter(params) {
                    let total = item.total &&item.total>1 ? item.total : ''
                    return total;
                  },
                }
                return item
              })??[]
              return data_a
            })??[]
          console.log(data1?.map(item => {
            return item.flat(Infinity)
          })??[],'data1')
          console.log(data1.flat(Infinity))
          this.option.series = data1?.flat(Infinity)??[]
          this.mapChart.setOption(this.option, true)
          this.option = this.mapChart.getOption()
        } else {
          this.option.series = []
          this.mapChart.setOption(this.option, true)
          this.option = this.mapChart.getOption()
        }
      },
      back() {
        this.mapChart.clear()
        this.drop = false
        this.option.geo[0].map = '河北'
        // 包含则返回加上区县地图数据
        if (this.hasCounty) {
          this.option.geo.push(countyJson)
        }
        this.option.series = []
        this.mapChart.setOption(this.option, true)
        this.$emit('selectCity', {
          name: '河北省',
          code: '',
        })
      },
      setGeoSetting(data) {
        let customSetting = []
        if (Array.isArray(data) && data.length) {
          data
                  .sort((a, b) => a.plantnum - b.plantnum)
                  .forEach((item, index) => {
                    let color = `rgba(5,173,90, 0.5)`
                    // let color = `rgba(5,173,90, ${(index + 1) * 0.1})`
                    customSetting.push({
                      name: item.areaName,
                      itemStyle: {
                        borderColor: '#B5E9FB',
                        areaColor: color,
                        shadowColor: 'rgba(5,173,90,1)',
                      },
                      emphasis: {
                        show: false,
                      },
                    })
                  })
        }
        this.option.geo[0].regions = customSetting
      },
    },
    watch: {
      geoData: {
        //监听的对象
        deep: true, //深度监听设置为 true
        handler: function () {
          this.setGeoSetting(JSON.parse(JSON.stringify(this.geoData)))
          this.mapChart.setOption(this.option, true)
        },
      },
    },
  }
</script>

<style scoped lang='scss'>
  .map-wrapper {
    position: relative;

    .back {
      position: absolute;
      width: 179px;
      height: 51px;
      background: url('back.png') no-repeat center/cover;
      cursor: pointer;
      z-index: 100;
      top: 10px;
      left: 10px;
    }

    .map {
      height: 100%;
    }
  }
</style>
