<template>
    <div class="list">
        <base-dialog 
            width="1000px" 
            label="品牌列表"
            v-show="dialogFlag"
            :show-close="true"
            @closeDialog="closeDialog"
        >
        <div class="wrap">
            <el-form :inline="true" class="myform">
                <el-form-item label="">
                    <el-input class="pub_input" v-model="form.companyName" placeholder="名称"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-select v-model="form.brandType" placeholder="类型" class="pub_input">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="有机食品" value="有机食品"></el-option>
                        <el-option label="绿色认证" value="绿色认证"></el-option>
                        <el-option label="地理标识" value="地理标识"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="listByBrandInfo()" class="btn" type="primary" icon="el-icon-search"></el-button>
                </el-form-item>
            </el-form>
            <div class="table_list">
                <el-table :data="tableData" stripe class="pub_table" height="100%">
                    <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                    <el-table-column prop="name" label="品牌名" width="150" align="center"></el-table-column>
                    <el-table-column prop="companyName" label="拥有企业" min-width="100" align="center"></el-table-column>
                    <el-table-column prop="number" label="编号" width="100" align="center"></el-table-column>
                    <el-table-column prop="awardTime" label="时间" width="200" align="center"></el-table-column>
                    <el-table-column label="详情" width="100" align="center">
                        <template slot-scope="scope">
                            <span class="look" @click="lookDetail(scope.row)">查看</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination
                class="pub_laypage" 
                :page-size="form.pageSize"
                @current-change="listByBrandInfo"
                :current-page.sync="form.pageNum"
                prev-text="上一页"
                next-text="下一页"
                :pager-count="11"
                layout="prev, pager, next,total"
                :total="total">
            </el-pagination>
        </div>
        </base-dialog>
        <!-- 品牌展示 -->
        <brand-detail ref="brandDetail"></brand-detail>
    </div>
</template>

<script>
import BaseDialog from '@/components/dialog/baseDialog' //弹框
import {listByBrandInfo} from '@/api/dialog' //接口
import BrandDetail from '@/components/brandDetail' //品牌展示
export default {
    components:{BaseDialog,BrandDetail},
    data(){
        return {
            form:{
                pageSize:15,
                pageNum:1,
                brandType:'',//类型
                companyName:'',//类型
            },
            tableData:[],
            total:0,
            dialogFlag:false,
        }
    },
    methods:{
        listByBrandInfo(page){//品牌列表
            this.form.pageNum=page||1
            this.form.parentAreaCodes=this.county||this.city
            listByBrandInfo(this.form).then(res=>{
                // console.log('品牌列表')
                // console.log(JSON.parse(JSON.stringify(res.rows)))
                this.tableData=res.rows||[]
                this.total=res.total||0
            }).catch(()=>{
                this.tableData=[]
                this.total=0
            })
        },
        closeDialog(){//关闭弹框
            this.dialogFlag=false
            this.total=0
            this.tableData=[]
            this.form={
                brandType:'',//类型
                pageNum:1,
                pageSize:15,
                companyName:'',
            }
        },
        show(obj){//
            this.dialogFlag=true
            if (obj&&Object.keys(obj).length!=0) {        
                Object.assign(this.form,obj)
            }
            this.listByBrandInfo()
        },
        lookDetail(v){//查看详情
            let obj={
                name:v.name,//品牌名称
                company_name:v.companyName,//品牌企业
                number:v.number,//品牌编号
                create_time:v.awardTime,//时间
                imgUrl:v.imgUrl,//时间
                // classify:v.classify,//类型
            }
            this.$refs.brandDetail.show(obj)
        },
    }
}
</script>

<style lang='scss' scoped>
    .list{
        // border: 1px solid #f00;
        .wrap{
            // border: 3px solid #0f0;
            height: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 20px;
            .myform{
                flex: none;
                .btn{
                    font-size: 20px;
                    padding: 0 20px;
                    height: 40px;
                }
            }
            .table_list{
                flex: 1;
                overflow: hidden;
                margin-bottom: 10px;
                .look{
                    color:#00ffff;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
            .pub_laypage{
                flex: none;
            }
        }
    }
</style>