import {request} from '@/utils/axios'

// 精品建设
// 品牌类型的总数
export const getBrandInfo = () => {
    return request({
        method: 'get',
        url: `${sysUrl}/brandBuild/countByType`
    })
}

// 城市-品牌种类统计列表
export const getBrandList = () => {
    return request({
        method: 'get',
        url: `${sysUrl}/brandBuild/countByCity`
    })
}

// 获取上图的列表
export const getBrandMapList = () => {
    return request({
        method: 'get',
        url: `${sysUrl}/brandBuild/getMapList`
    })
}

// 品牌展示
export const getBrandImageList = () => {
    return request({
        method: 'get',
        url: `${sysUrl}/web/brandInfo/getBandList`
    })
}

// 品牌推广
export const getBrandPromotionList = () => {
    return request({
        method: 'get',
        url: `${sysUrl}/brandBuild/getBrandPromotionList`
    })
}

// 查询推荐菜王列表
export const getPlantHotList = () => {
    return request({
        method: 'get',
        url: `${sysUrl}/web/suggestVegetable/list`,
        params: {
            pageNum: 1,
            pageSize: 7
        }
    })
}